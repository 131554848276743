import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const burger = el.querySelector('[data-burger]');
    const menu = burger.nextElementSibling;
    const closeBtn = el.querySelector('[data-close]');

    let menuIsOpen = false;

    const closeMenu = () => {
        if (!menuIsOpen) {
            return;
        }
        menuIsOpen = false;
        burger.setAttribute('aria-expanded', 'false');
        closeBtn.setAttribute('aria-expanded', 'false');
        menu.hidden = true;
        burger.focus();
    };

    const openMenu = () => {
        if (menuIsOpen) {
            return;
        }
        menuIsOpen = true;
        burger.setAttribute('aria-expanded', 'true');
        closeBtn.setAttribute('aria-expanded', 'true');
        menu.hidden = false;
        closeBtn.focus();
    };

    const toggleMenu = () => {
        if (!menuIsOpen) {
            openMenu();
        } else {
            closeMenu();
        }
    };

    const onBurgerClick = () => {
        toggleMenu();
    };

    const onCloseBtnClick = () => {
        closeMenu();
    };

    const onBodyKeyUp = e => {
        if (e.key === 'Escape') {
            closeMenu();
        }
    };

    // Focus trap
    const onKeyDown = e => {
        if (!menuIsOpen || (e.key !== 'Tab' && e.keyCode !== 9)) {
            return;
        }
        const firstFocusable = closeBtn;
        const lastFocusable = Array.from(el.querySelectorAll('a,button'))
            .pop() || firstFocusable;
        if (e.shiftKey && document.activeElement === firstFocusable) {
            e.preventDefault();
            lastFocusable.focus();
        } else if (!e.shiftKey && document.activeElement === lastFocusable) {
            e.preventDefault();
            firstFocusable.focus();
        }
    };

    const init = () => {
        el.addEventListener('keydown', onKeyDown);
        burger.addEventListener('click', onBurgerClick);
        closeBtn.addEventListener('click', onCloseBtnClick);
        document.body.addEventListener('keyup', onBodyKeyUp);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        el.removeEventListener('keydown', onKeyDown);
        burger.removeEventListener('click', onBurgerClick);
        closeBtn.removeEventListener('click', onCloseBtnClick);
        document.body.removeEventListener('keyup', onBodyKeyUp);
    };

    return {
        init,
        destroy
    };

};
