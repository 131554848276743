import gsap from 'gsap';
import debounce from 'lodash/debounce';
import Viewport from '../core/Viewport';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

const loadMatter = require('bundle-loader?lazy&name=[name]!matter-js');

export default el => {
    const $el = $(el);
    const $lampWrapper = $el.find('[data-lamp-wrapper]');
    const $lampContainer = $el.find('[data-lamp-container]');
    const $lamp = $el.find('[data-lamp]');
    const $image = $el.find('[data-lamp-image]');
    const $glow = $el.find('[data-lamp-glow]');
    const $glowFill = $el.find('[data-lamp-glow-fill]');

    let Matter = null;

    let engine = null;
    let world = null;
    let render = null;
    let runner = null;
    let pendulum = null;

    let mousePosition;
    let isDragging;
    let lastWidth = Viewport.width;
    let didBump = false;
    
    const glowColors = ['text-rorosgronn', 'text-kobberglod', 'text-rorosrosa'];

    const init = () => {
        $glow.removeClass(...glowColors);
        $glowFill.removeClass(...glowColors);
        
        const glowColor = glowColors[Math.floor(Math.random()*glowColors.length)];
        
        $glow.addClass(glowColor);
        $glowFill.addClass(glowColor);
        
        loadMatter(matter => {
            Matter = matter;
            initMatter();
            checkReady();
        });

        Viewport.on('resize', debounce(onResize, 10));
        Viewport.on('scroll', debounce(onScroll, 10));

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        destroyEngine();
        Viewport.off('resize');
        Viewport.off('scroll');
    };

    const onResize = () => {
        if (engine !== null && lastWidth !== Viewport.width) {
            initMatter();
        }

        lastWidth = Viewport.width;
    };

    const onScroll = () => {
        if (pendulum && !didBump) {
            const xVelocity = Matter.Body.getVelocity(pendulum.bodies[0]).x;

            if (Math.abs(xVelocity) < 0.008) {
                const amount = 10 * (xVelocity < 0 ? -1 : 1);
                Matter.Body.setVelocity(pendulum.bodies[0], { x: amount, y: amount });
                didBump = true;

                setTimeout(() => {
                    didBump = false;
                }, 1000);
            }
        }
    };

    const checkReady = () => {
        if ($image.hasClass('lazyloaded')) {
            showImage();
        } else {
            setTimeout(checkReady, 50);
        }
    };

    const showImage = () => {
        gsap.to($lamp.get(0), { duration: 1, opacity: 1, ease: 'sine.out' });
    };

    const initMatter = () => {
        console.log('initMatter');
        destroyEngine();

        const canvasWidth = $lampWrapper.width();
        const canvasHeight = $lamp.height() * 1.1;
        const lampWidth = $lamp.width();
        const lampHeight = $lamp.height();
        const objectWidth = lampWidth * 0.6;
        const objectHeight = lampHeight * 0.93;

        // create engine
        engine = Matter.Engine.create();
        world = engine.world;

        // create renderer
        render = Matter.Render.create({
            element: $lampWrapper.get(0),
            engine,
            options: {
                width: canvasWidth,
                height: canvasHeight,
                background: 'transparent',
                wireframes: false
            }
        });

        Matter.Render.run(render);

        // create runner
        runner = Matter.Runner.create();
        Matter.Runner.run(runner, engine);


        // add bodies
        pendulum = Matter.Composites.stack($lampContainer.position().left - (objectWidth * 0.5), $lampContainer.position().top, 1, 1, -20, 0, (x, y) => {
            return Matter.Bodies.rectangle(x, y, objectWidth, objectHeight, {
                frictionAir: 0.02,
                chamfer: 5,
                render: {
                    fillStyle: 'transparent',
                    lineWidth: 2,
                    strokeStyle: '#f00',
                    visible: false
                }
            });
        });

        engine.gravity.scale = 0.011;

        Matter.Composite.add(pendulum, Matter.Constraint.create({
            bodyB: pendulum.bodies[0],
            pointB: { x: 0, y: -objectHeight * 0.5 },
            pointA: { x: pendulum.bodies[0].position.x, y: pendulum.bodies[0].position.y - objectHeight * 0.47 },
            stiffness: 1,
            length: 0,
            render: {
                strokeStyle: '#f00'
            }
        }));

        Matter.Composite.add(world, pendulum);

        const boundaryTop = Matter.Bodies.rectangle(canvasWidth * 0.5, (-objectWidth - 40) * 0.5, canvasWidth, 20, { isStatic: true, visible: false, label: 'boundaryTop' });
        Matter.Composite.add(world, [boundaryTop]);

        // add mouse control
        const mouse = Matter.Mouse.create(render.canvas);
        const mouseConstraint = Matter.MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 1,
                render: {
                    visible: false
                }
            }
        });

        mouse.element.removeEventListener('mousewheel', mouse.mousewheel);
        mouse.element.removeEventListener('DOMMouseScroll', mouse.mousewheel);
        mouse.element.removeEventListener('touchmove', mouse.mousemove);
        mouse.element.removeEventListener('touchstart', mouse.mousedown);
        mouse.element.removeEventListener('touchend', mouse.mouseup);

        //mouse.element.addEventListener('touchstart', onTouchStartCheck);
        //mouse.element.addEventListener('touchend', onTouchEndCheck);

        Matter.Composite.add(world, mouseConstraint);

        // keep the mouse in sync with rendering
        render.mouse = mouse;

        // fit the render viewport to the scene
        Matter.Render.lookAt(render, {
            min: { x: 0, y: 0 },
            max: { x: canvasWidth, y: canvasHeight }
        });

        $el.on('mousemove', e => {
            mousePosition = { x: e.pageX, y: e.pageY };
            if (Matter.Query.point(pendulum.bodies, render.mouse.position).length > 0) {
                if (e.buttons === 1) {
                    el.style.cursor = 'grabbing';
                    isDragging = true;
                } else {
                    el.style.cursor = 'grab';
                    isDragging = false;
                }
            } else {
                el.style.cursor = '';
                isDragging = false;
            }
        });

        $el.on('mouseleave', e => {
            render.mouse.mouseup(e);
            isDragging = false;
            mousePosition = null;
        });

        Matter.Body.setVelocity(pendulum.bodies[0], { x: -20, y: -20 });

        Matter.Events.on(runner, 'afterTick', () => {
            gsap.set($lamp.get(0), { rotation: pendulum.bodies[0].angle * (180 / Math.PI) });
        });
    };

    const destroyEngine = () => {
        if (!engine) {
            return;
        }
        Matter.World.clear(world);
        Matter.Engine.clear(engine);
        Matter.Render.stop(render);
        Matter.Runner.stop(runner);
        $(render.canvas).remove();
        gsap.set($lamp.get(0), { rotation: 0 });
        render.canvas = null;
        render.context = null;
        render = null;
        engine = null;
        world = null;
        runner = null;
        pendulum = null;

        $el.off('mousemove');
        $el.off('mouseleave');
    };

    return {
        init,
        destroy
    };

};
