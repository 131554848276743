import Components from '../core/Components';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const tablist = el.querySelector('[role="tablist"]') || el;
    const tabs = tablist.querySelectorAll('[role="tab"]');

    if (!tabs.length) {
        console.warn('No tabs found');
        return null;
    }

    const getActiveTabIndex = () => Array.from(tabs).indexOf(tablist.querySelector('[role="tab"][aria-selected="true"]')) || 0;

    const selectTab = id => {
        tabs.forEach(tab => {
            const panelId = tab.getAttribute('aria-controls');
            const isActive = panelId === id;
            tab.setAttribute('aria-selected', isActive ? 'true' : 'false');
            tab.setAttribute('tabindex', isActive ? '0' : '-1');
            const panel = document.querySelector(`#${panelId}[role="tabpanel"]`);
            if (!panel) {
                console.warn(`Tab panel "${panelId}" not found`);
                return;
            }
            if (isActive) {
                tab.focus();
                panel.hidden = false;
                Components.init(panel);
            } else {
                panel.hidden = true;
                Components.destroy(panel);
            }
        });
    };

    const selectPreviousTab = () => {
        let newTabIndex = getActiveTabIndex() - 1;
        if (newTabIndex < 0) {
            newTabIndex = tabs.length - 1;
        }
        const activeTab = tabs[newTabIndex];
        selectTab(activeTab.getAttribute('aria-controls'));
    };

    const selectNextTab = () => {
        let newTabIndex = getActiveTabIndex() + 1;
        if (newTabIndex > (tabs.length - 1)) {
            newTabIndex = 0;
        }
        const activeTab = tabs[newTabIndex];
        selectTab(activeTab.getAttribute('aria-controls'));
    };

    const onTabClick = e => {
        if (e.target.getAttribute('aria-selected') === 'true') {
            return;
        }
        selectTab(e.target.getAttribute('aria-controls'));
    };

    const onTabListKeyDown = e => {
        if (e.key === 'ArrowRight') {
            selectNextTab();
        } else if (e.key === 'ArrowLeft') {
            selectPreviousTab();
        }
    };

    const init = () => {
        tabs.forEach(tab => {
            tab.addEventListener('click', onTabClick);
        });
        tablist.addEventListener('keydown', onTabListKeyDown);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        tabs.forEach(tab => {
            tab.removeEventListener('click', onTabClick);
        });
        tablist.removeEventListener('keydown', onTabListKeyDown);
    };

    return {
        init,
        destroy
    };

};
