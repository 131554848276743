import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, DOM_CHANGED } from './events';

const REWIND_REVEALS = false;
const DEFAULT_STAGGER = 100;

const timeouts = new WeakMap();
let tweens = new WeakMap();

let nodes = [];
let observer;
let intersecting = [];

const getTween = el => {

    let tween = tweens.get(el);

    if (tween) {
        return tween;
    }

    el.setAttribute('data-revealed', true);

    const {
        reveal: type,
        revealDelay: delay = 0
    } = el.dataset;
    let {
        revealDuration: duration,
        revealEase: ease
    } = el.dataset;

    if (duration !== undefined) {
        duration = parseFloat(duration);
    }

    tween = gsap.timeline({
        paused: true,
        onStart() {
            el.setAttribute('data-revealing', true);
        },
        onReverseComplete() {
            el.removeAttribute('data-revealing');
        },
        delay
    });

    if (type === 'y') {

        const { revealY: y = '5vmin' } = el.dataset;

        if (duration === undefined) {
            duration = 1.5;
        }

        if (ease === undefined) {
            ease = 'Quint.easeOut';
        }

        tween
            .fromTo(el.children, {
                y
            }, {
                y: 0,
                ease,
                duration
            }, 0)
            .fromTo(el, {
                opacity: 0.001
            }, {
                opacity: 1,
                duration: duration / 3,
                ease: 'Sine.easeInOut'
            }, 0);

    } else if (type === 'x' || type === 'left' || type === 'right') {

        let { revealX: x = '5vmin' } = el.dataset;

        let isLeft;

        if (type === 'left') {
            isLeft = true;
        } else if (type === 'right') {
            isLeft = false;
        } else {
            isLeft = el.getBoundingClientRect().left < (Viewport.width / 2);
        }

        if (isLeft) {
            x = `-${x}`;
        }

        if (duration === undefined) {
            duration = 1.3;
        }

        if (ease === undefined) {
            ease = 'Expo.easeOut';
        }

        tween
            .fromTo(el.children, {
                x
            }, {
                x: 0,
                ease,
                duration
            }, 0)
            .fromTo(el, {
                opacity: 0.001
            }, {
                opacity: 1,
                duration: 0.5,
                ease: 'Sine.easeInOut'
            }, 0);

    } else {

        // Default is fade
        if (duration === undefined) {
            duration = 0.5;
        }

        if (ease === undefined) {
            ease = 'Sine.easeInOut';
        }

        tween
            .fromTo(el, { opacity: 0.001 }, {
                opacity: 1,
                duration,
                ease
            });
    }

    if (type === 'y' || type === 'x') {
        tween.set([el, ...el.children], { clearProps: 'transform,opacity' });
    } else {
        tween.set(el, { clearProps: 'transform,opacity' });
    }

    if (el.hasAttribute('data-reveal-once')) {
        tween.play();
        el.removeAttribute('data-reveal');
        el.removeAttribute('data-revealed');
        el.removeAttribute('data-revealing');
        el.removeAttribute('data-reveal-once');
    }

    tweens.set(el, tween);

    return tween;

};

const trackNodes = () => {
    $('[data-reveal]:not([data-revealed])')
        .each(node => {
            observer.observe(node);
            nodes.push(node);
        });
};

const onObserve = entries => {

    entries.forEach(entry => {

        const {
            target,
            isIntersecting
        } = entry;

        // Get tween
        const tween = getTween(target);
        let timeout = timeouts.get(target);
        if (timeout) {
            clearTimeout(timeout);
            timeouts.delete(target);
        }

        if (isIntersecting || target.hasAttribute('data-reveal-always')) {
            intersecting.push(target);
            // Easiest way I could think of to sort the array of intersecting elements according to their chronological position in the DOM (which is a good idea)
            intersecting = nodes.filter(node => intersecting.indexOf(node) > -1);
        } else {
            intersecting = intersecting.filter(node => node !== target);
        }

        const { top } = target.getBoundingClientRect();

        if (!isIntersecting && top >= Viewport.height) {
            // Reset the effect?
            if (REWIND_REVEALS) {
                tween.pause(0, false);
            }
            return;
        }

        // Calculate base stagger
        let stagger = target.dataset.revealStagger;
        if (stagger === undefined) {
            stagger = DEFAULT_STAGGER;
        }
        stagger = parseInt(stagger, 10);

        if (!isIntersecting && top < 0) {

            tween.pause(tween.duration(), false);

        } else if (isIntersecting && !tween.progress()) {

            stagger *= Math.max(0, intersecting.filter(node => getTween(node) && getTween(node)
                .progress() <= 0.05)
                .indexOf(target));

            if (!stagger) {
                tween.play();
                return;
            }

            timeout = setTimeout(() => {
                clearTimeout(timeout);
                timeouts.delete(target);
                requestAnimationFrame(() => {
                    tween.play();
                });
            }, stagger);
        }

    });

};

const createObserver = () => {

    observer = new IntersectionObserver(onObserve, {
        threshold: [0, 0.5, 1],
        rootMargin: '0% 0% 0% 0%'
    });

};

const update = () => {

    if (!observer) {
        return;
    }

    // Unobserve nodes that are no longer in the DOM
    nodes = nodes.reduce((carry, node) => {
        if (node.closest('html')) {
            return carry.concat(node);
        }
        observer.unobserve(node);
        const tween = tweens.get(node);
        if (tween) {
            tweens.delete(node);
            tween.kill();
        }
        const timeout = timeouts.get(node);
        if (timeout) {
            timeouts.delete(node);
            clearTimeout(timeout);
        }
        return carry;
    }, []);

    trackNodes();
};

let hasInited = false;

const init = () => {

    if (hasInited) {
        return;
    }

    hasInited = true;

    createObserver();

    trackNodes();

    Viewport.on('breakpoint', update);

    Dispatch.on(DOM_CHANGED, update);

    if (ENV !== 'production') {
        setTimeout(() => {
            Dispatch.on(COMPONENT_INIT, () => {
                setTimeout(update, 0);
            });
        }, 0);
    }

};

const destroy = () => {
    if (observer) {
        observer.disconnect();
        observer = null;
    }
    nodes.forEach(node => {
        const tween = tweens.get(node);
        if (tween) {
            tween.progress(1).kill();
        }
        node.removeAttribute('data-revealed');
    });
    nodes = [];
    tweens = new WeakMap();
    hasInited = false;

    Viewport.off('breakpoint', update);

    Dispatch.off(DOM_CHANGED, update);
};

export default ({
    init,
    update,
    destroy,
    tweens
});
