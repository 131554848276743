import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default (el, props = {}) => {

    const decrementBtn = el.querySelector('[data-decrement]');
    const incrementBtn = el.querySelector('[data-increment]');
    const input = el.querySelector('input:not([type="hidden"])');
    const hiddenInput = el.querySelector('input[type="hidden"]');
    const valueLabel = el.querySelector('[data-value]');

    const { minValue = 0, maxValue } = props;

    const getValue = () => parseInt(input.value || '0', 10);

    const setValue = value => {
        let newValue = getValue() + value;
        if (newValue < minValue) {
            newValue = minValue;
        } else if (maxValue && newValue > maxValue) {
            newValue = maxValue;
        }
        input.value = newValue;
        hiddenInput.value = newValue;
        if (valueLabel) {
            valueLabel.textContent = newValue;
        }
    };

    const onDecrementClick = () => {
        setValue(-1);
    };

    const onIncrementClick = () => {
        setValue(1);
    };

    const init = () => {
        decrementBtn.addEventListener('click', onDecrementClick);
        incrementBtn.addEventListener('click', onIncrementClick);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        decrementBtn.removeEventListener('click', onDecrementClick);
        incrementBtn.removeEventListener('click', onIncrementClick);
    };

    return {
        init,
        destroy
    };

};
