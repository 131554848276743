import gsap from 'gsap';
import { reduceMotion } from '../lib/reducedMotion';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => ({
    burger: null,
    innerEl: null,
    menu: null,
    menuCloseBtn: null,
    menuContent: null,
    menuElements: null,
    menuShowHideTimeline: null,
    bookingWidgetBtn: null,
    bookingWidget: null,
    menuIsOpen: false,
    bookingWidgetIsOpen: false,
    bookingWidgetBtnIsHidden: false,
    bookingWidgetBtnShowHideTimeline: null,
    heroBookingWrapper: null,
    heroBookingWrapperIsIntersecting: null,
    heroBookingWrapperObserver: null,
    init() {
        this.burger = el.querySelector('[data-burger]');
        this.innerEl = document.getElementById('navbar-inner');
        this.menu = this.burger.nextElementSibling;
        this.menuCloseBtn = el.querySelector('[data-close]');
        this.menuContent = this.menu.querySelector('[data-content]');
        this.menuElements = Array.from(this.menu.querySelectorAll('[data-element]'));
        this.bookingWidgetBtn = el.querySelector('[data-bookingbtn]');
        this.bookingWidget = this.bookingWidgetBtn ? document.getElementById(this.bookingWidgetBtn.getAttribute('aria-controls')) : null;

        el.addEventListener('keydown', this.onKeyDown.bind(this));
        this.burger.addEventListener('click', this.onBurgerClick.bind(this));
        this.menuCloseBtn.addEventListener('click', this.onCloseBtnClick.bind(this));
        document.body.addEventListener('keyup', this.onBodyKeyUp.bind(this));
        document.body.addEventListener('click', this.onBodyClickOrFocus.bind(this));
        document.body.addEventListener('focusin', this.onBodyClickOrFocus.bind(this));
        window.addEventListener('beforeunload', this.onUnload.bind(this));

        if (this.bookingWidgetBtn) {
            this.bookingWidgetBtn.addEventListener('click', this.onBookingWidgetBtnClick.bind(this));
            if (this.bookingWidgetBtn.hidden) {
                this.heroBookingWrapper = document.getElementById('hero-booking');
                if (this.heroBookingWrapper) {
                    this.heroBookingWrapperObserver = new IntersectionObserver(([{ isIntersecting }]) => {
                        if (isIntersecting) {
                            this.hideBookingWidgetBtn();
                        } else {
                            this.showBookingWidgetBtn();
                        }
                        this.heroBookingWrapperIsIntersecting = isIntersecting;
                    });
                    this.heroBookingWrapperObserver.observe(this.heroBookingWrapper);
                    this.bookingWidgetBtnIsHidden = true;
                } else {
                    this.bookingWidgetBtn.hidden = false;
                    this.bookingWidgetBtnIsHidden = false;
                }
            }
        }

        Dispatch.emit(COMPONENT_INIT);
    },
    destroy() {
        this.closeMenu(false);
        this.closeBookingWidget(false);
        el.removeEventListener('keydown', this.onKeyDown.bind(this));
        this.burger.removeEventListener('click', this.onBurgerClick.bind(this));
        this.menuCloseBtn.removeEventListener('click', this.onCloseBtnClick.bind(this));
        document.body.removeEventListener('keyup', this.onBodyKeyUp.bind(this));
        document.body.removeEventListener('click', this.onBodyClickOrFocus.bind(this));
        document.body.removeEventListener('focusin', this.onBodyClickOrFocus.bind(this));
        window.removeEventListener('beforeunload', this.onUnload.bind(this));
        if (this.bookingWidgetBtn) {
            this.bookingWidgetBtn.removeEventListener('click', this.onBookingWidgetBtnClick.bind(this));
        }
        if (this.menuShowHideTimeline) {
            this.menuShowHideTimeline.kill();
            this.menuShowHideTimeline = null;
        }
        if (this.bookingWidgetBtnShowHideTimeline) {
            this.bookingWidgetBtnShowHideTimeline.kill();
            this.bookingWidgetBtnShowHideTimeline = null;
        }
        if (this.heroBookingWrapperObserver) {
            this.heroBookingWrapperObserver.disconnect();
            this.heroBookingWrapperObserver = null;
        }
    },
    createHideShowBookingWidgetTimeline() {
        const bookingWidgetBtnWidth = Math.round(this.bookingWidgetBtn.getBoundingClientRect().width);
        const bookingWidgetBtnMarginRight = parseInt(window.getComputedStyle(this.bookingWidgetBtn).marginRight.replace('px', ''), 10);
        const burgerWidth = this.burger ? this.burger.getBoundingClientRect().width : 0;
        const btnInner = this.bookingWidgetBtn.firstElementChild;
        const text = this.bookingWidgetBtn.querySelector('.ellipsis');
        const resetElements = () => {
            this.bookingWidgetBtnShowHideTimeline.kill();
            this.bookingWidgetBtnShowHideTimeline = null;
            gsap.set([this.bookingWidgetBtn, btnInner, this.innerEl, text], { clearProps: 'all' });
        };
        this.bookingWidgetBtnShowHideTimeline = gsap.timeline({
            paused: true,
            onComplete: resetElements,
            onReverseComplete: () => {
                resetElements();
                this.bookingWidgetBtn.hidden = true;
            }
        })
            .set(this.bookingWidgetBtn, { position: 'absolute', visibility: '', top: 0, right: '100%', x: bookingWidgetBtnMarginRight + burgerWidth, scaleX: 0, transformOrigin: 'right center' }, 0)
            .to(this.bookingWidgetBtn, { x: 0, scaleX: 1, duration: 0.5, ease: 'Back.easeOut' }, 0)
            .set(text, { opacity: 0, scaleX: 2, transformOrigin: 'center center' }, 0)
            .to(text, { scaleX: 1, duration: 0.5, ease: 'Back.easeOut' }, 0)
            .to(text, { opacity: 1, duration: 0.3, ease: 'Sine.easeOut' }, 0.2)
            .to(this.innerEl, { x: (bookingWidgetBtnWidth + bookingWidgetBtnMarginRight) / 2, duration: 0.5, ease: 'none' }, 0);
    },
    showBookingWidgetBtn() {
        if (!this.bookingWidgetBtnIsHidden) {
            return;
        }
        gsap.set(this.bookingWidgetBtn, { position: 'absolute', visibility: 'hidden' });
        this.bookingWidgetBtnIsHidden = false;
        this.bookingWidgetBtn.hidden = false;
        if (!this.bookingWidgetBtnShowHideTimeline) {
            this.createHideShowBookingWidgetTimeline();
        }
        gsap.killTweensOf(this.createHideShowBookingWidgetTimeline);
        gsap.to(this.bookingWidgetBtnShowHideTimeline, { progress: 1, duration: 0.5, ease: 'Quad.easeInOut' });
    },
    hideBookingWidgetBtn(tween = true) {
        if (this.bookingWidgetBtnIsHidden || this.bookingWidgetIsOpen) {
            return;
        }
        this.bookingWidgetBtnIsHidden = true;
        this.closeBookingWidget(); // TODO delay if open
        if (!this.bookingWidgetBtnShowHideTimeline) {
            this.createHideShowBookingWidgetTimeline();
            this.bookingWidgetBtnShowHideTimeline.pause(this.bookingWidgetBtnShowHideTimeline.totalDuration(), true);
        }
        gsap.killTweensOf(this.createHideShowBookingWidgetTimeline);
        if (tween) {
            gsap.to(this.bookingWidgetBtnShowHideTimeline, { progress: 0, duration: 0.5, ease: 'Quad.easeInOut' });
        } else {
            this.bookingWidgetBtnShowHideTimeline.pause(0, false);
        }
    },
    afterMenuClose() {
        this.menu.hidden = true;
        if (this.menuShowHideTimeline) {
            this.menuShowHideTimeline.kill();
            this.menuShowHideTimeline = null;
        }
        gsap.set([this.menu, this.menuContent].concat(this.menuElements), { clearProps: 'opacity,transform' });
        document.documentElement.classList.remove('persist-nav');
    },
    closeMenu(tween = true) {
        if (!this.menuIsOpen) {
            return;
        }
        this.closeBookingWidget(tween);
        this.menuIsOpen = false;
        this.burger.setAttribute('aria-expanded', 'false');
        this.menuCloseBtn.setAttribute('aria-expanded', 'false');
        this.burger.focus();
        if (this.menuShowHideTimeline) {
            this.menuShowHideTimeline.kill();
            this.menuShowHideTimeline = null;
        }
        if (!tween) {
            this.afterMenuClose();
            return;
        }
        this.menuShowHideTimeline = gsap.timeline({
            onComplete: this.afterMenuClose.bind(this)
        })
            .to(this.menuContent, { opacity: 0, duration: 0.2 }, 0)
            .to(this.menu, { opacity: 0, duration: 0.3 }, 0);
    },
    openMenu() {
        if (this.menuIsOpen) {
            return;
        }
        this.closeBookingWidget();
        this.menuIsOpen = true;
        document.documentElement.classList.add('persist-nav');
        this.burger.setAttribute('aria-expanded', 'true');
        this.menuCloseBtn.setAttribute('aria-expanded', 'true');
        this.menu.hidden = false;
        this.menuCloseBtn.focus();
        if (this.menuShowHideTimeline) {
            this.menuShowHideTimeline.kill();
        }
        this.menuShowHideTimeline = gsap.timeline({
            onComplete: () => {
                this.menuShowHideTimeline.kill();
                this.menuShowHideTimeline = null;
            }
        })
            .fromTo(this.menu, { opacity: 0 }, { opacity: 1, duration: 0.3, ease: 'Cubic.easeIn' }, 0);
        if (this.menuElements.length && !reduceMotion()) {
            this.menuShowHideTimeline
                .fromTo(this.menuElements, { x: -20 }, { x: 0, duration: 1.5, ease: 'Quint.easeOut', stagger: 0.03 }, 0)
                .fromTo(this.menuElements, { opacity: 0 }, { opacity: 1, duration: 0.3, stagger: 0.03 }, 0);
        }
    },
    toggleMenu() {
        if (!this.menuIsOpen) {
            this.openMenu();
        } else {
            this.closeMenu();
        }
    },
    openBookingWidget() {
        if (this.bookingWidgetIsOpen || !this.bookingWidget) {
            return;
        }
        this.closeMenu();
        this.bookingWidgetIsOpen = true;
        this.bookingWidget.hidden = false;
        this.bookingWidget.nextElementSibling.hidden = true;
        this.bookingWidgetBtn.setAttribute('aria-expanded', 'true');
        document.documentElement.classList.add('booking-open');
    },
    closeBookingWidget(tween = true) {
        if (!this.bookingWidgetIsOpen || !this.bookingWidget) {
            return;
        }
        this.bookingWidgetIsOpen = false;
        this.bookingWidget.hidden = true;
        this.bookingWidget.nextElementSibling.hidden = false;
        this.bookingWidgetBtn.setAttribute('aria-expanded', 'false');
        if (!document.activeElement || document.activeElement.offsetParent === null) {
            this.bookingWidgetBtn.focus();
        }
        if (this.heroBookingWrapper && this.heroBookingWrapperIsIntersecting) {
            setTimeout(() => {
                this.hideBookingWidgetBtn(tween);
            }, tween ? 100 : 0);
        }
        document.documentElement.classList.remove('booking-open');
    },
    toggleBookingWidget() {
        if (this.bookingWidgetIsOpen) {
            this.closeBookingWidget();
        } else {
            this.openBookingWidget();
        }
    },
    onBurgerClick() {
        this.toggleMenu();
    },
    onCloseBtnClick() {
        this.closeMenu();
    },
    onBodyKeyUp(e) {
        if (e.key !== 'Escape') {
            return;
        }
        if (this.bookingWidgetIsOpen && !e.target.classList.contains('flatpickr-input') && !e.target.closest('[data-disclosure-target]')) {
            this.closeBookingWidget();
        } else if (this.menuIsOpen) {
            this.closeMenu();
        }
    },
    onBodyClickOrFocus(e) {
        if (!this.bookingWidgetIsOpen || e.target === this.bookingWidgetBtn || this.bookingWidgetBtn.contains(e.target) || e.target === this.bookingWidget || this.bookingWidget.contains(e.target) || e.target.closest('.flatpickr-calendar')) {
            return;
        }
        this.closeBookingWidget();
    },
    // Focus trap
    onKeyDown(e) {
        if (!this.menuIsOpen || (e.key !== 'Tab' && e.keyCode !== 9)) {
            return;
        }
        const firstFocusable = this.menuCloseBtn;
        const lastFocusable = Array.from(el.querySelectorAll('a,button'))
            .pop() || firstFocusable;
        if (e.shiftKey && document.activeElement === firstFocusable) {
            e.preventDefault();
            lastFocusable.focus();
        } else if (!e.shiftKey && document.activeElement === lastFocusable) {
            e.preventDefault();
            firstFocusable.focus();
        }
    },
    onBookingWidgetBtnClick() {
        this.toggleBookingWidget();
    },
    onUnload() {
        this.closeMenu();
    }
});
