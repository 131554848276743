/**
 * @returns {boolean}
 */
export const isTouch = () => !document.documentElement.classList.contains('using-mouse');

/**
 * @returns {number}
 */
export const getScrollTop = () => ((window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop);

/**
 * @returns {boolean}
 */
export const isLivePreview = () => document.documentElement.classList.contains('is-preview') && document.documentElement.classList.contains('is-iframe');

/**
 *
 * @type {{customEvent: plausible.customEvent}}
 */
export const plausible = {
    customEvent: event => {
        if (!event || !window.plausible) {
            return;
        }
        console.info('Track custom event', { event });
        try {
            window.plausible(event);
        } catch (error) {
            console.error(error);
        }
    }
};
