import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

const loadLottie = require('bundle-loader?lazy&name=[name]!lottie-web');

export default el => {
    const $el = $(el);
    const $lottieWrap = $el.find('[data-steam-container]');
    const $imageWrap = $el.find('[data-image-container]');
    const $horseImage = $el.find('[data-horse-image]');
    const $horseJacket = $el.find('[data-horse-jacket]');
    
    let steamAnimation = null;
    let lottiePlayTimer = null;

    const jacketColors = ['text-rorosgronn', 'text-kobberglod', 'text-rorosrosa', 'text-kobber'];
    const init = () => {
        $horseJacket.removeClass(...jacketColors);
        $horseJacket.addClass(jacketColors[Math.floor(Math.random()*jacketColors.length)]);
        
        
        loadLottie(lottie => {
            steamAnimation = lottie.loadAnimation({
                container: $lottieWrap.get(0),
                renderer: 'canvas',
                loop: true,
                autoplay: true,
                path: '/assets/static/kaffestuggu/kaffestuggu-steam.json'
            });

            steamAnimation.addEventListener('DOMLoaded', () => {
                console.log('DOMLoaded!');
                checkReady();
            });
        });
        Dispatch.emit(COMPONENT_INIT);
    };
    
    const destroy = () => {
        if (steamAnimation) {
            steamAnimation.destroy();
        }
    };
    
    const checkReady = () => {
        if ($horseImage.hasClass('lazyloaded')) {
            showImage();
        } else {
            setTimeout(checkReady, 50);
        }
    };
    
    const showImage = () => {
        gsap.to($imageWrap.get(0), { duration: 1, opacity: 1, ease: 'sine.out' });
    };

    return {
        init,
        destroy
    };

};
