import gsap from 'gsap';
import Components from '../core/Components';
import animatedScroll from '../lib/animatedScroll';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, DOM_CHANGED } from '../lib/events';

export default (el, props = {}) => {

    const button = el.querySelector('a');
    const listing = document.querySelector('#listing > ul');
    const announcer = document.getElementById('announcer');

    if (!listing) {
        console.warn('Listing container not found');
        return null;
    }

    let abortController;
    let href = new URL(button.href);

    const onClick = e => {
        e.preventDefault();

        if (abortController) {
            abortController.abort();
        }
        abortController = new AbortController();

        if (announcer && props.loadingAnnouncement) {
            announcer.textContent = props.loadingAnnouncement;
        }

        el.classList.add('loading');

        fetch(href.pathname, { signal: abortController.signal })
            .then(response => response.text())
            .then(html => {
                const template = document.createElement('template');
                template.innerHTML = html;
                const newListing = template.content.querySelector('#listing > ul');
                const newButton = newListing.parentNode.querySelector('[data-component="LoadMore"] a');
                if (newButton) {
                    href = new URL(newButton.href);
                } else {
                    gsap.timeline({
                        onComplete() {
                            el.hidden = true;
                        }
                    })
                        .to(el, { opacity: 0, duration: 0.3 });
                }
                const numNewNodes = newListing.children.length;
                const numOldNodes = listing.children.length;
                Array.from(newListing.children).forEach(node => {
                    listing.appendChild(node);
                });
                Components.init(listing);
                Dispatch.emit(DOM_CHANGED);
                const firstNewNode = Array.from(listing.children)[numOldNodes];
                try {
                    firstNewNode.querySelector('a').focus({ preventScroll: true });
                } catch (error) {
                    console.error(error);
                }
                animatedScroll(firstNewNode);
                if (announcer && props.loadedAnnouncement) {
                    announcer.textContent = props.loadedAnnouncement.replace('{count}', numNewNodes);
                } else {
                    announcer.textContent = '';
                }
            })
            .catch(error => {
                console.error(error);
                announcer.textContent = props.errorAnnouncement || '';
            })
            .finally(() => {
                abortController = null;
                el.classList.remove('loading');
            });
    };

    const onButtonKeyDown = e => {
        if (e.key === 'Enter' || e.key === ' ') {
            onClick(e);
        }
    };

    const init = () => {
        button.removeAttribute('href');
        button.setAttribute('role', 'button');
        button.setAttribute('tabIndex', '0');
        button.addEventListener('keydown', onButtonKeyDown);
        button.addEventListener('click', onClick);
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        button.removeEventListener('keydown', onButtonKeyDown);
        button.removeEventListener('click', onClick);
    };

    return {
        init,
        destroy
    };

};
