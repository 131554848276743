import gsap from 'gsap';
import Components from '../core/Components';
import animatedScroll from '../lib/animatedScroll';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, DOM_CHANGED } from '../lib/events';

export default (el, props) => {

    const {
        target: targetSelector,
        announcer: announcerSelector,
        loadingAnnouncement,
        scrollOffsetY
    } = props;
    const target = document.querySelector(targetSelector);
    const announcer = document.querySelector(announcerSelector);

    if (!target) {
        console.error(`Target ${target} not found`);
        return null;
    }

    let abortController;

    const onSortButtonClick = e => {
        e.preventDefault();
        e.stopPropagation();

        const { target: link } = e;

        if (link.getAttribute('aria-current') === 'page') {
            return;
        }

        el.querySelector('[aria-current="page"]')
            .removeAttribute('aria-current');
        link.setAttribute('aria-current', 'page');
        const { href } = link;
        window.history.replaceState(null, '', href);
        animatedScroll(el, { duration: 0.5 }, scrollOffsetY || 0);

        if (announcer && loadingAnnouncement) {
            announcer.textContent = loadingAnnouncement;
        }

        if (abortController) {
            abortController.abort();
        }
        abortController = new AbortController();

        fetch(href, { signal: abortController.signal })
            .then(response => response.text())
            .then(html => {
                const template = document.createElement('template');
                template.innerHTML = html;
                const newTarget = template.content.querySelector(targetSelector);
                gsap.timeline()
                    .to(target, {
                        opacity: 0,
                        duration: 0.3,
                        ease: 'Cubic.easeOut'
                    })
                    .add(() => {
                        Components.destroy(target);
                        target.innerHTML = newTarget.innerHTML;
                        Components.init(target);
                        Dispatch.emit(DOM_CHANGED);
                    })
                    .to(target, {
                        opacity: 1,
                        duration: 0.3,
                        ease: 'Cubic.easeIn'
                    })
                    .add(() => {
                        if (!announcer) {
                            return;
                        }
                        const { announcement } = link.dataset;
                        if (announcement) {
                            announcer.textContent = announcement;
                        } else {
                            announcer.textContent = '';
                        }
                    });
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                abortController = null;
            });
    };

    const init = () => {
        Array.from(el.querySelectorAll('a'))
            .forEach(link => {
                link.addEventListener('click', onSortButtonClick);
            });
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        Array.from(el.querySelectorAll('a'))
            .forEach(link => {
                link.removeEventListener('click', onSortButtonClick);
            });
    };

    return {
        init,
        destroy
    };

};
