import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default (el, props = {}) => {

    const $el = $(el);
    const $flashlight = $el.find('[data-flashlight]');
    
    let hoverContainerToX = null;
    let hoverContainerToY = null;
    
    const init = () => {
        const startX = $flashlight.offset().left;
        const startY = $flashlight.offset().top;
        
        $flashlight.removeClass('center');
        gsap.set($flashlight.get(0), { x: startX, y: startY, left: 0, top: 0 });
        
        hoverContainerToX = gsap.quickTo($flashlight.get(0), 'x', { duration: 1, ease: 'quint.out' });
        hoverContainerToY = gsap.quickTo($flashlight.get(0), 'y', { duration: 1, ease: 'quint.out' });

        document.body.addEventListener('mousemove', onMouseMove);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        document.body.removeEventListener('mousemove', onMouseMove);
    };
    
    const onMouseMove = e => {
        hoverContainerToX(e.pageX);
        hoverContainerToY(e.pageY);
    };

    return {
        init,
        destroy
    };

};
