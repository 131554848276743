import gsap from 'gsap';
import { plausible } from './helpers';
import Config from '../core/Config';

export default {
    clearBookingWidgetFormErrors(form) {
        form.querySelectorAll('[data-input] .error').forEach(errorNode => {
            errorNode.textContent = '';
        });
    },
    showBookingWidgetFormErrors(form, errors) {
        Object.keys(errors).forEach(key => {
            const input = form.querySelector(`[data-input="${key}"]`);
            if (!input) {
                return;
            }
            const errorNode = input.querySelector('.error');
            if (errorNode) {
                errorNode.textContent = errors[key].join(' ');
            }
            const focusable = input.querySelector('input:not([hidden]),button');
            if (focusable) {
                focusable.focus();
                gsap.timeline({
                    repeat: 2
                })
                    .to(focusable, { x: 3, duration: 0.05, ease: 'none' })
                    .to(focusable, { x: 0, duration: 0.05, ease: 'none' })
                    .to(focusable, { x: -3, duration: 0.05, ease: 'none' })
                    .to(focusable, { x: 0, duration: 0.05, ease: 'none' })
                    .set(focusable, { clearProps: 'transform' });
            }
        });
    },
    submitBookingWidgetForm(form) {
        if (form.classList.contains('loading')) {
            return;
        }
        form.classList.add('loading');
        this.clearBookingWidgetFormErrors(form);
        const scope = this;
        fetch(window.location.href, {
            body: new FormData(form),
            headers: {
                Accept: 'application/json'
            },
            method: 'post'
        })
            .then(res => {
                if (res.ok) {
                    // Track to plausible
                    plausible.customEvent(Config.get('events.booking'));
                    return res.json();
                }
                res.json()
                    .then(({ errors }) => scope.showBookingWidgetFormErrors(form, errors));
                throw new Error();
            })
            .then(data => {
                if (data.redirect) {
                    window.location.href = data.redirect;
                } else {
                    throw new Error('No redirect URL');
                }
            })
            .catch(error => {
                form.classList.remove('loading');
                console.error(error);
            });
    }
};
