import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const input = el.querySelector('input');
    const output = el.querySelector('output');

    const updateProgress = () => {
        output.textContent = input.value;
        input.style.setProperty('--value', input.value);

        const percentProgress = (((input.value - input.min) / (input.max - input.min)) * 100);
        const pixelOffset = 32 * (percentProgress / 100);
        output.style.setProperty('left', `calc(${percentProgress}% - ${pixelOffset}px)`);
    };

    const init = () => {
        input.style.setProperty('--value', input.value);
        input.style.setProperty('--min', input.min === '' ? '0' : input.min);
        input.style.setProperty('--max', input.max === '' ? '100' : input.max);

        input.addEventListener('input', updateProgress);
        updateProgress();

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        input.removeEventListener('input', updateProgress);
    };

    return {
        init,
        destroy
    };

};
