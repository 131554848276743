/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const COMPONENT_INIT = 'COMPONENT_INIT';
export const DOM_CHANGED = 'DOM_CHANGED';
export const PROGRAMMATIC_SCROLL_START = 'PROGRAMMATIC_SCROLL_START';
export const PROGRAMMATIC_SCROLL_END = 'PROGRAMMATIC_SCROLL_END';
export const REDUCED_MOTION_CHANGE = 'REDUCED_MOTION_CHANGE';
export const UNLOAD = 'UNLOAD';
