import { loadFlatpickr } from '../lib/async-bundles';
import Viewport from '../core/Viewport';

export default (el, props) => {

    const {
        id = '',
        fromPlaceholder = '',
        toPlaceholder = '',
        minDate = null,
        maxDate = null,
        appendToId = null,
        positionElementId = null,
        position = 'auto',
        mode = 'range',
        onChange = null
    } = props || {};

    const isRange = mode === 'range';

    const fromInput = document.getElementById(props.fromInput);
    const toInput = isRange ? document.getElementById(props.toInput) : null;
    const fromValue = document.getElementById(`${props.fromInput}-value`);
    const toValue = isRange ? document.getElementById(`${props.toInput}-value`) : null;
    const buttons = Array.from(el.querySelectorAll('button[aria-expanded]'));

    let Flatpickr;
    let picker;
    let input;
    let isOpen = false;
    let selectToDate = false;

    const defaultFromDate = fromInput.value;
    const defaultToDate = toInput ? toInput.value : null;
    const defaultDate = [];
    if (defaultFromDate) {
        defaultDate.push(defaultFromDate);
        if (defaultToDate) {
            defaultDate.push(defaultToDate);
        }
    }

    const isSmall = () => ['m', 'mp', 'l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) === -1;

    const numMonths = () => (isSmall() ? 1 : 2);

    let wasSmall = isSmall();

    const setActivePickerButton = () => {
        if (!picker || !buttons.length) {
            return;
        }
        if (isRange) {
            if (picker.selectedDates.length === 1) {
                buttons[0].classList.remove('is-active');
                buttons[1].classList.add('is-active');
            } else {
                buttons[0].classList.add('is-active');
                buttons[1].classList.remove('is-active');
            }
        } else {
            buttons[0].classList.add('is-active');
        }
    };

    const openPicker = e => {
        if (!picker) {
            return;
        }
        selectToDate = isRange ? e.currentTarget.nextElementSibling === toInput : false;
        if (!isOpen) {
            picker.open();
        } else if (selectToDate && picker.selectedDates.length) {
            picker.selectedDates = [picker.selectedDates[0]];
        } else if (!selectToDate && isRange && picker.selectedDates.length < 2) {
            picker.setDate([fromInput.value, toInput.value], false, 'd-m-Y');
        }
        setActivePickerButton();
    };

    const onInputKeyDown = e => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            e.stopPropagation();
            openPicker(e);
        }
    };

    const destroyPickr = () => {
        if (picker) {
            picker.close();
            picker.destroy();
            picker = null;
        }
        if (input) {
            input.removeEventListener('keydown', onInputKeyDown);
            input.remove();
            input = null;
        }
    };

    const initPickr = () => {
        destroyPickr();
        // Create the input
        input = document.createElement('input');
        input.type = 'text';
        input.className = 'text-transparent z-1 bg-transparent';
        input.tabIndex = '-1';
        input.setAttribute('form', el.closest('form').id);
        if (props.label) {
            input.setAttribute('aria-label', props.label);
        }
        input.classList.add('absolute', 'full');
        el.appendChild(input);
        picker = Flatpickr(input, {
            showMonths: numMonths(),
            mode,
            defaultDate,
            position: isSmall() ? 'auto' : position,
            positionElement: positionElementId && !isSmall() ? document.getElementById(positionElementId) : null,
            appendTo: appendToId && !isSmall() ? document.getElementById(appendToId) : document.body,
            ignoredFocusElements: buttons,
            onChange(selectedDates) {
                let [fromDate, toDate] = selectedDates;
                if (!fromDate) {
                    fromDate = picker.parseDate(defaultDate[0], 'd-m-Y');
                }
                if (isRange && !toDate) {
                    toDate = fromDate.fp_incr(1);
                }
                fromInput.value = fromDate ? picker.formatDate(fromDate, 'd-m-Y') : '';
                if (isRange && toInput) {
                    toInput.value = fromDate && toDate ? picker.formatDate(toDate, 'd-m-Y') : '';
                }
                if (fromValue) {
                    fromValue.value = fromDate ? picker.formatDate(fromDate, 'j. M')
                        .toLowerCase() : fromPlaceholder;
                    if (fromDate) {
                        fromValue.parentNode.classList.add('has-value');
                    } else {
                        fromValue.parentNode.classList.remove('has-value');
                    }
                }
                if (toValue) {
                    toValue.value = fromDate && toDate ? picker.formatDate(toDate, 'j. M')
                        .toLowerCase() : toPlaceholder;
                    if (fromDate && toDate) {
                        toValue.parentNode.classList.add('has-value');
                    } else {
                        toValue.parentNode.classList.remove('has-value');
                    }
                }
                if (buttons[0]) {
                    const fromDay = buttons[0].querySelector('[data-day]');
                    if (fromDay) {
                        fromDay.textContent = picker.formatDate(fromDate, 'j.');
                    }
                    const fromMonth = buttons[0].querySelector('[data-month]');
                    if (fromMonth) {
                        fromMonth.textContent = picker.formatDate(fromDate, 'F');
                    }
                    const fromYear = buttons[0].querySelector('[data-year]');
                    if (fromYear) {
                        fromYear.textContent = picker.formatDate(fromDate, 'Y');
                    }
                }
                if (isRange && buttons[1]) {
                    const toDay = buttons[1].querySelector('[data-day]');
                    if (toDay) {
                        toDay.textContent = picker.formatDate(toDate, 'j.');
                    }
                    const toMonth = buttons[1].querySelector('[data-month]');
                    if (toMonth) {
                        toMonth.textContent = picker.formatDate(toDate, 'F');
                    }
                    const toYear = buttons[1].querySelector('[data-year]');
                    if (toYear) {
                        toYear.textContent = picker.formatDate(toDate, 'Y');
                    }
                }
                if (isOpen) {
                    setActivePickerButton();
                }
                if (onChange) {
                    onChange({ selectedDates, picker });
                }
            },
            onOpen() {

                if (id) {
                    picker.calendarContainer.id = id;
                }

                isOpen = true;
                input.focus();

                buttons.forEach(button => {
                    button.setAttribute('aria-expanded', 'true');
                });

                // This is a hack that makes it possible to only set the "to date", if the picker was opened via the "to date" button
                if (selectToDate && picker.selectedDates.length) {
                    picker.selectedDates = [picker.selectedDates[0]];
                }

                setActivePickerButton();

                document.body.classList.add('picker-open');
            },
            onClose() {
                isOpen = false;
                selectToDate = false;

                buttons.forEach(button => {
                    button.setAttribute('aria-expanded', 'false');
                    button.classList.remove('is-active');
                });

                if (isRange && picker.selectedDates.length < 2) {
                    picker.setDate([fromInput.value, toInput.value], false, 'd-m-Y');
                }

                document.body.classList.remove('picker-open');
            },
            minDate,
            maxDate
        });
        input.addEventListener('keydown', onInputKeyDown);
        if (isOpen) {
            picker.open();
        }
    };

    const onBreakpoint = () => {
        if (!Flatpickr) {
            return;
        }
        if (wasSmall !== isSmall()) {
            initPickr();
        }
        wasSmall = isSmall();
    };

    const init = () => {
        loadFlatpickr(({ default: flatpickr }) => {
            Flatpickr = flatpickr;
            initPickr();
        });

        Viewport.on('breakpoint', onBreakpoint);

        el.querySelectorAll('button[aria-expanded]').forEach(button => {
            button.addEventListener('click', openPicker);
        });

    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        el.querySelectorAll('button[aria-expanded]').forEach(button => {
            button.removeEventListener('click', openPicker);
        });
        destroyPickr();
    };

    const close = () => {
        if (!picker) {
            return;
        }
        picker.close();
    };

    return {
        init,
        destroy,
        close
    };

};
