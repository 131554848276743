import $ from '../core/Dom';
import Components from '../core/Components';
import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

const loadMapbox = require('bundle-loader?lazy&name=[name]!mapbox-gl');

export default (el, props) => {

    const $el = $(el);
    const $mapContainer = $el.find('[data-map-container]');

    const { markers, apiKey } = props;

    let observer = null;
    let Mapbox = null;
    let openPopup = null;

    const init = () => {
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                return;
            }
            observer.disconnect();
            observer = null;
            loadMapbox(mapboxgl => {
                Mapbox = mapboxgl;
                createMapbox();
                document.body.addEventListener('keyup', onBodyKeyUp);
                Dispatch.emit(COMPONENT_INIT);
            });
        });
        observer.observe(el);
    };

    const destroy = () => {
        document.body.removeEventListener('keyup', onBodyKeyUp);
    };

    const createMapbox = () => {
        Mapbox.accessToken = apiKey;

        const map = new Mapbox.Map({
            container: $mapContainer.get(0),
            scrollZoom: false,
            style: 'mapbox://styles/roroshotellene/clxeky876002r01qm8xbf81w4',
            center: [11.383, 62.5775],
            zoom: 14,
            attributionControl: false,
            cooperativeGestures: true
        }).addControl(new Mapbox.AttributionControl({
            compact: true
        }));

        const nav = new Mapbox.NavigationControl();
        map.addControl(nav, 'top-right');

        markers.forEach(marker => {
            const popup = new Mapbox.Popup({ maxWidth: 360, offset: 24 }).setHTML(marker.popupContent);
            const mapMarker = new Mapbox.Marker({
                element: $('<div class="w-[24px] h-[24px] bg-kobberglod rounded-[12px]"></div>').get(0)
            })
                .setLngLat([marker.location[1], marker.location[0]])
                .setPopup(popup)
                .addTo(map);

            popup.on('open', onPopupOpen);
            popup.on('close', onPopupClose);
        });
    };

    const onPopupOpen = e => {
        const { target } = e;

        Components.destroy(target.getElement());
        Components.init(target.getElement());

        openPopup = target;
    };

    const onPopupClose = () => {
        openPopup = null;
    };

    const onBodyKeyUp = e => {
        if (e.key === 'Escape' && openPopup !== null) {
            openPopup.remove();
            openPopup = null;
        }
    };
    
    return {
        init,
        destroy
    };
};
