import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';
import BookingHelper from '../lib/BookingHelper';
import DatePicker from './DatePicker';

export default form => {

    let observer = null;
    let datePicker = null;

    const onSubmit = e => {
        e.preventDefault();
        BookingHelper.submitBookingWidgetForm(form);
    };

    const destroyDatePicker = () => {
        if (!datePicker) {
            return;
        }
        datePicker.destroy();
        datePicker = null;
    };

    const initDatepicker = () => {
        if (datePicker) {
            return;
        }
        const datePickerEl = form.querySelector('[data-datepicker]');
        const datePickerProps = JSON.parse(datePickerEl.dataset.datepicker);
        datePicker = DatePicker(datePickerEl, datePickerProps);
        datePicker.init();
    };

    const init = () => {
        form.addEventListener('submit', onSubmit);
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (isIntersecting) {
                initDatepicker();
            } else if (datePicker) {
                destroyDatePicker();
            }
        });
        observer.observe(form);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        if (observer) {
            observer.disconnect();
            observer = null;
        }
        destroyDatePicker();
        form.removeEventListener('submit', onSubmit);
    };

    return {
        init,
        destroy
    };

};
