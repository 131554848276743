import gsap from 'gsap';
import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import Dispatch from './core/Dispatch';
import lazySizes from './lib/lazysizes';
import Revealer from './lib/Revealer';
//import Transitioner from './lib/Transitioner';
import { isLivePreview, plausible } from './lib/helpers';
import { REDUCED_MOTION_CHANGE } from './lib/events';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    lazySizes.init();

    const reduceMotionQuery = window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)');

    const onReduceMotionChange = () => {
        if (!reduceMotionQuery.matches) {
            if (!isLivePreview()) {
                Revealer.init();
                //Transitioner.init();
            }
        } else {
            Revealer.destroy();
            //Transitioner.destroy();
        }
        Dispatch.emit(REDUCED_MOTION_CHANGE, !!reduceMotionQuery.matches);
    };

    onReduceMotionChange();

    reduceMotionQuery.onchange = onReduceMotionChange;

    window.addEventListener('popstate', () => {
        window.location.reload();
    });

    // Track clicks on buttons and links to Plausible
    $('body').on('click', 'a[data-plausible-event],button[data-plausible-event]', e => {
        const { triggerTarget: target } = e;
        if (target.getAttribute('aria-disabled') === 'true' || !target.dataset.plausibleEvent) {
            return;
        }
        plausible.customEvent(target.dataset.plausibleEvent);
    });

};

require('doc-ready')(init);
