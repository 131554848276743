import { loadFlickity } from '../lib/async-bundles';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default (el, props = {}) => {

    const $el = $(el);
    const slider = $el.find('[data-slider]').get(0) || el;
    const counter = $el.find('[data-counter]').get(0);
    const nav = $el.find('[data-nav]').get(0);
    const prevBtn = nav ? nav.querySelector('[data-nav="prev"]') : null;
    const nextBtn = nav ? nav.querySelector('[data-nav="next"]') : null;

    let observer;

    let flkty;
    let prevSelectedIndex = 0;

    let Flickity;

    const updateCounter = () => {
        if (!counter || !flkty) {
            return;
        }
        counter.textContent = flkty.selectedIndex + 1;
    };

    const updateNav = () => {
        if (!nav || !flkty) {
            return;
        }
        const {
            size,
            slideableWidth
        } = flkty;
        if (Math.round(size.width) >= Math.round(slideableWidth)) {
            // Hide the nav completely if the slider isn't... slideable.
            nav.hidden = true;
            return;
        }
        nav.hidden = false;
    };

    const destroyFlickity = () => {
        if (!flkty) {
            return;
        }
        flkty.destroy();
        flkty = null;
        $(el)
            .off('click');
        $(slider)
            .off('focusin click');
        prevSelectedIndex = 0;
    };

    const createFlickity = () => {

        destroyFlickity();

        flkty = new Flickity(slider, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            groupCells: true,
            prevNextButtons: false,
            pageDots: false,
            freeScroll: false,
            freeScrollFriction: 0.045,
            selectedAttraction: 0.015,
            friction: 0.22,
            resize: false,
            adaptiveHeight: false,
            setGallerySize: true,
            wrapAround: false,
            accessibility: true,
            percentPosition: false,
            on: {
                resize() {
                    updateNav();
                    updateCounter();
                },
                dragStart() {
                    document.ontouchmove = e => e.preventDefault();
                    this.slider.querySelectorAll('a,button').forEach(link => {
                        link.style.pointerEvents = 'none';
                    });
                },
                dragEnd() {
                    document.ontouchmove = () => true;
                    this.slider.querySelectorAll('a,button').forEach(link => {
                        link.style.pointerEvents = '';
                    });
                },
                select() {
                    this.cells.forEach(({ element }) => {
                        element.removeAttribute('aria-hidden');
                    });
                    if (prevBtn && nextBtn) {
                        if (!this.options.wrapAround && this.selectedIndex <= 0) {
                            prevBtn.setAttribute('aria-disabled', 'true');
                            prevBtn.setAttribute('tabindex', '-1');
                        } else {
                            prevBtn.removeAttribute('aria-disabled');
                            prevBtn.removeAttribute('tabindex');
                        }
                        if (!this.options.wrapAround && this.selectedIndex >= this.slides.length - 1) {
                            nextBtn.setAttribute('aria-disabled', 'true');
                            nextBtn.setAttribute('tabindex', '-1');
                        } else {
                            nextBtn.removeAttribute('aria-disabled');
                            nextBtn.removeAttribute('tabindex');
                        }
                    }
                    // Which direction are we going?
                    if (!this.options.wrapAround) {
                        if (this.selectedIndex > prevSelectedIndex) {
                            this.slider.classList.remove('flickity-move-prev');
                            this.slider.classList.add('flickity-move-next');
                        } else {
                            this.slider.classList.remove('flickity-move-next');
                            this.slider.classList.add('flickity-move-prev');
                        }
                    }
                    updateCounter();
                },
                ready() {
                    setTimeout(() => {
                        this.resize();
                    }, 0);
                }
            },
            ...props
        });

        $(slider)
            .on('focusin', 'a', e => {
                slider.parentNode.scrollLeft = 0;
                const { triggerTarget: link } = e;
                const cell = flkty.cells.find(({ element }) => element.contains(link));
                if (!cell) {
                    return;
                }
                const index = flkty.cells.indexOf(cell);
                if (index > -1) {
                    flkty.selectCell(index);
                }
            });

        $(el)
            .on('click', '[data-nav="prev"]', () => {
                flkty.previous();
            });

        $(el)
            .on('click', '[data-nav="next"]', () => {
                flkty.next();
            });

        updateNav();

        console.log('created flickity');

    };

    const onResize = () => {
        if (!flkty) {
            return;
        }
        setTimeout(() => {
            flkty.resize();
        }, 0);
    };

    const onBreakpoint = () => {
        createFlickity();
    };

    const destroy = () => {
        if (observer) {
            observer.disconnect();
            observer = null;
        }
        if (flkty) {
            destroyFlickity();
            Viewport.off('resize', onResize);
            Viewport.off('breakpoint', onBreakpoint);
        }
    };

    const init = () => {
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                return;
            }
            observer.disconnect();
            observer = null;
            loadFlickity(module => {
                Flickity = module.default;
                createFlickity();
            });
            Viewport.on('resize', onResize);
            Viewport.on('breakpoint', onBreakpoint);
        });
        observer.observe(el);

        Dispatch.emit(COMPONENT_INIT);
    };

    return {
        init,
        destroy
    };

};
