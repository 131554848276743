import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';
import { plausible } from '../lib/helpers';

export default form => {

    const $form = $(form);
    const errorMessage = $form.find('[data-error]').get(0);
    const submitBtn = $form.find('button[type="submit"]').get(0);

    const announcer = $form.find('[data-announcer]').get(0);
    const statuses = JSON.parse(announcer.dataset.statuses);

    let isSubmitting = false;

    const disableSubmit = () => {
        submitBtn.setAttribute('aria-disabled', 'true');
    };

    const enableSubmit = () => {
        submitBtn.removeAttribute('aria-disabled');
    };

    // Clear input fields
    const clearInputs = () => {
        $form.find('input:not([type="hidden"]),textarea,select').get().forEach(input => {
            if (input.nodeName === 'SELECT') {
                input.selectedIndex = 0;
            } else if (['radio', 'checkbox'].indexOf(input.getAttribute('type')) > -1) {
                input.checked = false;
            } else {
                input.value = null;
            }
            try {
                input.dispatchEvent(new Event('change'));
            } catch (error) {
                console.error(error);
            }
        });
    };

    const showGenericError = () => {
        errorMessage.hidden = false;
        errorMessage.focus = true;
    };

    const clearErrors = () => {
        if (errorMessage) {
            errorMessage.hidden = true;
        }
        $form.find('[data-errors]').each(node => {
            node.firstElementChild.innerHTML = '';
            node.hidden = true;
        });
        $form.find('[data-input]').removeClass('has-errors');
    };

    const getErrorNames = (errors, prefix = '') => Object.keys(errors || {}).reduce((carry, key) => {
        if (typeof errors[key] === 'object' && !Array.isArray(errors[key])) {
            return {
                ...carry,
                ...getErrorNames(errors[key], key)
            };
        }
        const name = prefix ? `${prefix}[${key}]` : key;
        return {
            ...carry,
            [name]: errors[key]
        };
    }, {});

    const showErrors = (errors = []) => {

        clearErrors();

        const errorsByName = getErrorNames(errors);
        const errorNames = Object.keys(errorsByName);

        if (!errorNames.length) {
            showGenericError();
            return;
        }

        errorNames.forEach(name => {
            const input = $form.find(`[data-input="${name}"]`).get(0);
            if (input) {
                input.classList.add('has-errors');
            }
            const errorsDiv = $form.find(`[data-errors="${name}"]`).get(0);
            if (errorsDiv) {
                errorsDiv.hidden = false;
                errorsDiv.firstElementChild.innerHTML = [].concat(errorsByName[name]).join('<br/>');
            }
        });

        const firstErrorInput = $form.find('[data-input].has-errors').find('input:not([type="hidden"]),textarea,select').get(0);
        if (firstErrorInput) {
            try {
                firstErrorInput.focus();
            } catch (error) {
                // Don't care.
            }
        }

        enableSubmit();

    };

    const showReceipt = () => {};

    const onSubmit = async e => {

        e.preventDefault();

        if (isSubmitting || submitBtn.hasAttribute('aria-disabled')) {
            return;
        }

        isSubmitting = true;
        disableSubmit();
        clearErrors();

        submitBtn.classList.add('loading');

        announcer.textContent = statuses.submitting;

        const data = new FormData($form.get(0));
        const url = window.location.href.split('?')[0].split('#')[0];

        const response = await fetch(url, {
            method: 'POST',
            body: new URLSearchParams(data).toString(),
            mode: 'same-origin',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
                Accept: 'application/json'
            },
            referrerPolicy: 'same-origin'
        });

        const { status } = response;

        response.json()
            .then(res => {
                if (status === 200) {
                    const { plausibleEvent } = form.dataset;
                    if (plausibleEvent) {
                        plausible.customEvent(plausibleEvent);
                    }
                    announcer.textContent = statuses.submitted;
                    clearInputs();
                    const { redirect } = res;
                    if (redirect) {
                        if (redirect.startsWith('http')) {
                            window.location.href = res.redirect;
                        } else {
                            window.location.href = `/${res.redirect}`;
                        }
                    } else {
                        showReceipt();
                    }
                } else if (res.errors) {
                    announcer.textContent = statuses.errors;
                    showErrors(res.errors);
                } else {
                    announcer.textContent = statuses.failure;
                    showGenericError();
                }
            })
            .finally(() => {
                isSubmitting = false;
                submitBtn.classList.remove('loading');
                enableSubmit();
            });
    };

    const init = () => {
        $form.on('submit', onSubmit);
        $form.find('a[href]:not([href^="#"])').each(link => {
            $(link).attr({
                target: '_blank',
                rel: 'noopener noreferrer'
            });
        });

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        $form.off('submit', onSubmit);
    };

    return {
        init,
        destroy
    };
};
